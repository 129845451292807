<template>
    <div class="product-conflicting-container">
        <div class="product-conflicting-content">
            <div class="product-conflicting-back">
                <chevron @click.native="installQuit()" :iconDirection="'left'" />
            </div>
            <div class="product-conflicting-title">
                {{ $t("Conflicting_question") }}
            </div>
        </div>
        <div class="image-container">
            <img class="product-conflicting-image" :src="boardName | image_board_name" />
        </div>
        <div class="product-conflicting-footer">
            <div class="product-conflicting-buttonsgroup">
                <router-link to="/conflicting-security">
                    <b-button class="b-btn-checkout product-conflicting-button-finish" block variant="primary">
                        {{ $t("Conflicting_new_user") }}
                    </b-button>
                </router-link>
                <router-link to="/conflicting-unlink">
                    <b-button class="b-btn-checkout product-conflicting-button-finish" block variant="outline-primary">
                        {{ $t("Conflicting_shared_account") }}
                    </b-button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Chevron from '../svg/chevron.vue';
export default {
    components: { Chevron },
    name: "ProductConflicting",

    data() {
        return {
        };
    },

    computed: {
        ...mapGetters("devices", ["getBoardName"]),
        boardName() {
            let parameters = this.$route.query;
            if (this.getBoardName) {
                return this.getBoardName;
            } else {
                return parameters["board_name"];
            }
        },
    },

    watch: {
    },

    methods: {
        ...mapActions("users", ["setToken"]),
        ...mapActions("devices", ["setMac", "setBoardName", "setRemaining"]),
        contactUs() {
            window.location.href = '/contact_us/';
        },
        logout() {
            window.location.href = '/logout/';
        },
        main() {
            window.location.href = '/main/';
        },
        installQuit() {
            window.location.href = '/install_quit/';
        },
        installRestart() {
            window.location.href = '/install_restart/';
        }
    },

    async mounted() {
        window.zE("webWidget", "hide");
    },

    async created() {
        console.log('version 1.0.0')
        let parameters = this.$route.query;
        if (parameters["token"]) {
            let token = parameters["token"];
            this.setToken(token);
        }
        if (parameters["mac"]) {
            let mac = parameters["mac"];
            this.setMac(mac);
        }
        if (parameters["board_name"]) {
            let board_name = parameters["board_name"];
            this.setBoardName(board_name);
        }
        if(parameters["remaining"] == 0) {
            this.contactUs()
        }
    },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.product-conflicting-container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-conflicting-content {
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        padding: 1.5em;
    }

    .product-conflicting-footer {
        display: flex;
        gap: 1.5em;
        padding: 1.5em;
        width: 100%;
    }

    .product-conflicting-title {
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        font-size: 28px;
        text-align: left;
    }

    .product-alert-text {
        text-align: left;
        color: #f95658 !important;
        font-weight: 500 !important;
    }

    .image-container {
        flex: 1;
        display: flex;
        position: relative;
        justify-content: center;
    }

    .product-conflicting {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        &-password {
            display: block !important;

            &-eyes {
                position: absolute;
                right: 2.5em;
                margin-top: 1em;
            }
        }

        &-image {
            height: 100%;
            position: absolute;
        }

        &-description {
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #555b62;
        }

        &-buttonsgroup {
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            width: 100%;

            .button-expand {
                padding: 0em 1em !important;

                &-header {
                    padding: 1em 0em;
                }
            }

            .is-invalid {
                color: #f95658 !important;
            }

            .product-conflicting-button.is-invalid {
                border: 2px solid #f95658 !important;
                font-weight: 500 !important;
                background: transparent !important;
            }

            .product-conflicting-button {
                background: #ffffff;
                border: 2px solid #eaeaea;
                box-sizing: border-box;
                border-radius: 8px;
                padding: 1em;
                width: 100%;

                &:focus {
                    box-shadow: none;
                }

                &:focus-visible {
                    outline: none;
                }

                &-finish {
                    font-size: 18px;
                    padding: 1em;
                }

                &-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: left;
                }

                &-text {
                    width: 90%;
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #555b62;
                }
            }

            .btn-secondary:not(:disabled):not(.disabled).is-invalid {
                border: 1px solid #f95658 !important;
                background: #feeeee;
                box-shadow: 0px 0px 0px 1px #feeeee !important;
            }

            .btn-secondary:not(:disabled):not(.disabled).active {
                border: 1px solid #0090f8 !important;
                box-shadow: 0px 0px 0px 1px #0090f8 !important;

                .product-conflicting-button-text {
                    color: black;
                }
            }

            .account-expand-summary {
                display: flex;
                flex-direction: column;
                gap: 0.5em;

                .expand-summary-item {
                    font-family: "Open Sans";
                    font-weight: 400;
                    font-size: 14px;
                    color: #555b62;
                    text-align: left;

                    &:last-child {
                        padding-bottom: 1em;
                    }
                }

                .expand-summary-item.is-invalid {
                    color: #f95658 !important;
                    font-weight: 500 !important;
                    border: none !important;
                    background: transparent !important;
                }
            }

            .button-expand-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .button-expand {
                &-title {
                    color: #050328;
                    font-family: "Open Sans", sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    text-align: left;
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5em;
                    border-top: #eaeaea 2px solid;
                    padding: 1em 0em;
                }

                &-inputgroup {
                    display: flex;
                    flex-direction: column;
                }

                &-label {
                    font-family: "Open Sans";
                    font-weight: 400;
                    font-size: 14px;
                    color: #555b62;
                    text-align: left;
                }

                &-input {
                    background: #ffffff;
                    border: 1px solid #8b949f;
                    box-sizing: border-box;
                    border-radius: 8px;
                    font-family: "Open Sans";
                    font-size: 14px;
                    padding: 0.75em;
                    margin-top: 0.5em;

                    &:focus-visible {
                        border: 1px solid #0090f8 !important;
                        box-shadow: 0px 0px 0px 1px #0090f8 !important;
                        outline: none;
                        background: white;
                    }
                }

                .is-invalid {
                    border: 1px solid #f95658 !important;
                    background: #feeeee;

                    &:focus-visible {
                        border: 1px solid #f95658 !important;
                        box-shadow: 0px 0px 0px 1px #feeeee !important;
                    }
                }
            }
        }
    }
}
</style>